<template>
  <div class="d-flex product-promo-filter">
    <v-switch
      v-if="!hidePromoFilter"
      :input-value="promoFilter"
      @change="handlePromoFilter"
      class="mt-0 py-2 promo-checkbox-wrap"
      hide-details
    >
      <template v-slot:label>
        <v-icon color="primary" class="mr-1">$promo</v-icon>
        {{ $t("filter.promo") }}
      </template>
    </v-switch>
    <v-switch
      :input-value="newFilter"
      @change="handleNewFilter"
      class="mt-0 py-2 promo-checkbox-wrap"
      hide-details
    >
      <template v-slot:label>
        <v-icon color="primary" class="mr-1">$sparkle</v-icon>
        {{ $t("filter.new") }}
      </template>
    </v-switch>
    <v-switch
      :input-value="preferredFilter"
      @change="handlePreferredFilter"
      class="mt-0 py-2 promo-checkbox-wrap"
      hide-details
    >
      <template v-slot:label>
        <v-icon color="primary" class="mr-1">$starLight</v-icon>
        {{ $t("filter.preferred") }}
      </template>
    </v-switch>
  </div>
</template>

<script>
export default {
  name: "ProductPromoFilter",
  props: ["promoFilter", "newFilter", "preferredFilter", "hidePromoFilter"],
  methods: {
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    },
    handlePreferredFilter(preferred) {
      this.$emit("handlePreferredFilter", preferred);
    }
  }
};
</script>
<style lang="scss">
.product-promo-filter {
  gap: 16px;
}
</style>
