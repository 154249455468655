<template>
  <div class="filtered-checkbox-list" v-click-outside="onClickOutside">
    <template v-if="alphaWrap">
      <v-expansion-panels multiple>
        <v-expansion-panel
          v-for="(items, letter) in groupedItems"
          :key="letter"
        >
          <v-expansion-panel-header>
            {{ letter }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              multiple
              hide-details
              v-for="item in items"
              :key="item.id"
              :value="item.id"
              :disabled="item.filteredCount == 0"
              v-model="selectedFacetsXS[facet.parameterName]"
              :label="`${item.name} (${item.filteredCount})`"
              @click="handleFacetFilter()"
              class="d-flex align-center"
            >
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <template v-else>
      <v-checkbox
        multiple
        hide-details
        v-for="value in filter"
        :key="value.id"
        :value="value.id"
        :disabled="value.filteredCount == 0"
        v-model="selectedFacetsXS[facet.parameterName]"
        :label="`${value.name} (${value.filteredCount})`"
        @click="handleFacetFilter()"
        class="d-flex align-center"
      >
      </v-checkbox>
      <div class="mt-2 d-flex">
        <a
          :aria-label="$t('filter.showMore')"
          :title="$t('filter.showMore')"
          v-if="facet.values.length > 6 && panelLength == 6"
          class="text-caption font-weight-bold"
          @click="panelLength = facet.values.length"
        >
          {{ $t("filter.showMore") }}
        </a>
        <a
          :aria-label="$t('filter.showLess')"
          :title="$t('filter.showLess')"
          v-else-if="facet.values.length > 6"
          class="text-caption font-weight-bold"
          @click="panelLength = 6"
        >
          {{ $t("filter.showLess") }}
        </a>
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.v-expansion-panel-header {
  min-height: 25px !important;
  padding: 8px 12px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 12px 8px !important;
}
</style>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "FilterCheckboxList",
  data() {
    return {
      panelLength: 6
    };
  },
  props: {
    facet: { type: Object },
    facetsFilter: { type: Object },
    filteredProductsQty: { type: Number },
    alphaWrap: { type: Boolean, required: false, default: false }
  },
  computed: {
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    filter() {
      return this.facet.values.slice(0, this.panelLength);
    },
    calcMaxHeight() {
      if (this.filteredProductsQty > 0 && this.filteredProductsQty <= 4) {
        return `${window.innerHeight - 278}px`;
      }
      return "100%";
    },
    groupedItems() {
      const grouped = this.facet.values.reduce((acc, item) => {
        const firstChar = item.name[0].toUpperCase();
        const groupKey = /[A-Z]/.test(firstChar) ? firstChar : "#";

        if (!acc[groupKey]) acc[groupKey] = [];
        acc[groupKey].push(item);

        return acc;
      }, {});

      const sortedKeys = Object.keys(grouped).sort((a, b) => {
        if (a === "#") return 1;
        if (b === "#") return -1;
        return a.localeCompare(b);
      });

      const sortedGrouped = {};
      sortedKeys.forEach(key => {
        sortedGrouped[key] = grouped[key];
      });

      return sortedGrouped;
    }
  },
  methods: {
    handleFacetFilter() {
      this.$emit("handleFacetFilter", this.selectedFacetsXS);
    },
    onClickOutside() {
      this.panelLength = 6;
    }
  }
};
</script>
